<template>
  <div class="installationContactList">
    <LoadingPlaceholder v-if="loading" />
    
    <template v-else>
      <Grid
        :ref="kgm_ref"
        :style="{height: 'auto'}"
        :data-items="kgm_computedGridItems(contacts)"
        :columns="kgm_responsiveColumns()"
        :filterable="true"
        :filter="kgm_filter"
        :pageable="kgm_pagable"
        :page-size="kgm_take"
        :skip="kgm_skip"
        :take="kgm_take"
        :total="kgm_allGridItems(contacts)"
        :sortable="{
          allowUnsort: kgm_allowUnsort,
          mode: kgm_sortMode
        }"
        :sort="kgm_sort"
        selected-field="selected"
        @rowclick="kgm_gridOnRowClick"
        @filterchange="kgm_gridFilterChange"
        @pagechange="kgm_gridPageChange"
        @sortchange="kgm_gridSortChange"
      >
        <template
          slot="notificationCell"
          slot-scope="{props}"
        >
          <td :class="props.className">
            <div style="display: flex; justify-content: center;">
              <JiraNotificationStatus
                :jira-notification-media="props.dataItem.notificationMediaJira"
              />
              /
              <template>
                <font-awesome-icon
                  v-if="props.dataItem.keycloakRoles && (props.dataItem.keycloakRoles.includes('scope_staff') ||
                    props.dataItem.keycloakRoles.includes('scope_customer'))"
                  v-tooltip="props.dataItem.keycloakRoles.toString()"
                  icon="door-open"
                  size="2x"
                />
                <font-awesome-icon
                  v-else-if="props.dataItem.isInKeycloak && (!props.dataItem.keycloakRoles || (props.dataItem.keycloakRoles &&
                    !(props.dataItem.keycloakRoles.includes('scope_staff') ||
                      props.dataItem.keycloakRoles.includes('scope_customer'))))"
                  v-tooltip="props.dataItem.keycloakRoles ? props.dataItem.keycloakRoles.toString() : 'no roles'"
                  icon="door-closed"
                  size="2x"
                  style="color: blue;"
                />
                <font-awesome-icon
                  v-else-if="!props.dataItem.isInKeycloak"
                  icon="door-closed"
                  size="2x"
                  style="color: black;"
                />
              </template>
            </div>
          </td>
        </template>
        <div
          slot="nameFilter"
          slot-scope="{props, methods}"
          class="input-group"
        >
          <input
            ref="nameFilterInput"
            :value="props.value"
            type="text"
            class="form-control mr-2 rounded-right"
            placeholder="please enter a name"
            @input="(event) => {methods.change({operator: 'contains', field: props.field, value: event.target.value, syntheticEvent: event});}"
          >
          <div
            v-if="props.value"
            class="input-group-append"
          >
            <button
              class="btn btn-light border rounded"
              @click="kgm_resetInput('nameFilterInput', (event) => {methods.change({operator: '', field: '', value: '', syntheticEvent: event});})"
            >
              <font-awesome-icon
                class="gray"
                icon="times"
              />
            </button>
          </div>
        </div>
        <div
          slot="phoneFilter"
          slot-scope="{props, methods}"
          class="input-group"
        >
          <input
            ref="phoneFilterInput"
            :value="props.value"
            type="text"
            class="form-control mr-2 rounded-right"
            placeholder="please enter a number"
            @input="(event) => {methods.change({operator: 'contains', field: props.field, value: event.target.value, syntheticEvent: event});}"
          >
          <div
            v-if="props.value"
            class="input-group-append"
          >
            <button
              class="btn btn-light border rounded"
              @click="kgm_resetInput('phoneFilterInput', (event) => {methods.change({operator: '', field: '', value: '', syntheticEvent: event});})"
            >
              <font-awesome-icon
                class="gray"
                icon="times"
              />
            </button>
          </div>
        </div>
        <div
          slot="emailFilter"
          slot-scope="{props, methods}"
          class="input-group"
        >
          <input
            ref="emailFilterInput"
            :value="props.value"
            type="text"
            class="form-control mr-2 rounded-right"
            placeholder="please enter a email"
            @input="(event) => {methods.change({operator: 'contains', field: props.field, value: event.target.value, syntheticEvent: event});}"
          >
          <div
            v-if="props.value"
            class="input-group-append"
          >
            <button
              class="btn btn-light border rounded"
              @click="kgm_resetInput('emailFilterInput', (event) => {methods.change({operator: '', field: '', value: '', syntheticEvent: event});})"
            >
              <font-awesome-icon
                class="gray"
                icon="times"
              />
            </button>
          </div>
        </div>
        <div
          slot="roleFilter"
          slot-scope="{props, methods}"
          class="input-group"
        >
          <input
            ref="roleFilterInput"
            :value="props.value"
            type="text"
            class="form-control mr-2 rounded-right"
            placeholder="please enter a role"
            @input="(event) => {methods.change({operator: 'contains', field: props.field, value: event.target.value, syntheticEvent: event});}"
          >
          <div
            v-if="props.value"
            class="input-group-append"
          >
            <button
              class="btn btn-light border rounded"
              @click="kgm_resetInput('roleFilterInput', (event) => {methods.change({operator: '', field: '', value: '', syntheticEvent: event});})"
            >
              <font-awesome-icon
                class="gray"
                icon="times"
              />
            </button>
          </div>
        </div>
        <template
          slot="optionsTemplate"
          slot-scope="{props}"
        >
          <td :class="props.className">
            <div
              class="btn-group-vertical"
            >
              <div
                v-if="!(props.dataItem.roleAllowMultiple && authenticationHasRole('scope_customer'))"
                class="btn-group"
              >
                <button
                  class="btn btn-primary btn-sm"
                  @click="show('edit', props.dataItem)"
                >
                  <font-awesome-icon
                    class="mr-2"
                    icon="edit"
                  />
                  <span>{{ $t('edit') }}</span>
                </button>
              </div>
              
              <button
                class="btn btn-secondary btn-sm"
                @click="openConfirmInstallationModal(props.dataItem)"
              >
                <font-awesome-icon
                  class="mr-2 gray"
                  icon="trash"
                />
                <span>{{ $t('removeFromInstallation') }}</span>
              </button>
            </div>
          </td>
        </template>
      </Grid>
      <Sidebar
        v-if="showSidebar"
        :show-sidebar="showSidebar"
        @close="close"
      >
        <ContactsAddToInstallation
          v-if="sidebarType == 'addInstallation'"
          :installation-id="installationId"
          @reload="getContacts"
        />
        <ContactsEdit
          v-else-if="sidebarType == 'edit'"
          :installation-id="installationId"
          :contact="selectedContact"
          @reload="getContacts"
        />
      </Sidebar>
      <SweetModal
        ref="confirmInstallationDelete"
        :title="$t('introOutroListComp.deleteElement')"
        icon="warning"
        blocking
        class="overflowHidden"
      >
        <p v-if="selectedContact">
          {{ $t('sureToDeleteContactFromInstallation', {'0' : selectedContact.fullname}) }}
        </p>
        <button
          slot="button"
          class="btn btn-secondary float-left mb-3"
          @click="$refs.confirmInstallationDelete.close()"
        >
          <font-awesome-icon
            class="mr-2"
            icon="times"
          />{{ $t('cancel') }}
        </button>
        <button
          slot="button"
          class="btn btn-danger float-right mb-3"
          @click="removeContactFromInstallation()"
        >
          <font-awesome-icon
            class="mr-2"
            icon="trash"
          />{{ $t('delete') }}
        </button>
        <div class="clearfix" />
      </SweetModal>
    </template>
  </div>
</template>

<script>
import { kendoGridMixin } from "@/mixins/kendoGridMixin.js";
import { urlParseMixin } from '@/mixins/urlParseMixin';
import { mapGetters } from 'vuex';
import { authenticationMixin } from '@/mixins/authenticationMixin';
import Sidebar from '@/components/Base/Sidebar.vue';
import { SweetModal } from 'sweet-modal-vue';
import ContactsEdit from '@/components/Contacts/ContactsEdit.vue';
import ContactsAddToInstallation from '@/components/Contacts/ContactsAddToInstallation.vue';

export default {
  name: "InstallationContactList",
  components: {
    JiraNotificationStatus: () => import('@/components/Jira/JiraNotificationStatus.vue'),
    Sidebar,
    SweetModal,
    ContactsEdit,
    ContactsAddToInstallation
  },
  mixins: [kendoGridMixin, urlParseMixin, authenticationMixin],
  props: {
    installationId: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      loading: true,
      contacts: null,
      showSidebar: false,
      sidebarType: null,
      selectedContact: null,
      kgm_filter: {
        logic: "and",
        filters: [
          {
            field: "key",
            operator: "contains",
            value: ""
          }
        ]
      },
      kgm_sort: [
        {
          field: "key",
          dir: "asc"
        }
      ],
      kgm_columns: [
        {
          field: 'fullname',
          filterable: true,
          title: 'Name',
          filterCell: 'nameFilter',
        },
        {
          field: 'phone',
          filterable: true,
          title: this.$t('phone'),
          filterCell: 'phoneFilter',
          hideOn: ['xsDown']
        },
        {
          field: 'email',
          filterable: true,
          title: this.$t('email'),
          filterCell: 'emailFilter',
          hideOn: ['xsDown']
        },
        {
          field: 'customerAbbrevation',
          filterable: true,
          filter: 'text',
          title: this.$t('customer'),
          filterCell: "nameFilter"
        },
        {
          field: 'roleName',
          filterable: true,
          title: 'Role',
          filterCell: 'roleFilter',
          hideOn: ['xsDown']
        },
        {
          field: 'notificationMedia',
          filterable: false,
          title: `${this.$t('contactNotificationMedia')} / Metis Login`,
          cell: 'notificationCell',
          hideOn: ['xsDown']
        },
        {
          filterable: false,
          title: 'Options',
          cell: 'optionsTemplate',
        }
      ]
    };
  },
  computed: {
    ...mapGetters(['store_getJiraUrl']),
  },
  created () {
    this.getContacts();
  },
  methods: {
    getNotificationMedia (e) {
      if (!e) {
        return null
      }
      if (e.includes('sms') && e.includes('email')) {
        return 'SMS and Email'
      }
      if (e.includes('sms')) {
        return 'SMS'
      }
      if (e.includes('email')) {
        return 'Email'
      }
      if (e.includes('voice')) {
        return 'Voice'
      }
      return null
    },
    jiraHref: function (id) {
      return this.urlParseMixin_combine(this.store_getJiraUrl, `browse/${ id }`);
    },
    getName (first, last) {      
      if(first != null & last != null) {
        return first + ' ' + last;
      }
      else if(first != null && !last) {
        return first;
      }
      else if(!first && last != null) {
        return last;
      }

      return '';
    },
    show (val, contact) {
      if (contact) {
        this.selectedContact = contact;
      }
      this.sidebarType = val;
      this.showSidebar = true;
    },
    close () {
      this.showSidebar = false;
    },
    openConfirmInstallationModal (contact) {
      this.selectedContact = Object.assign({}, contact);
      this.$refs.confirmInstallationDelete.open();
    },
    //api calls
    getContacts () {
      this.close();
      if (this.$refs.confirmDelete) {
        this.$refs.confirmDelete.close();
      }
      if (this.$refs.confirmInstallationDelete) {
        this.$refs.confirmInstallationDelete.close();
      }
      this.loading = true;
      this.axios.get('/Contact/Contacts?installationId=' + this.installationId)
        .then((response) => {
          if (response == null) {
            return;
          }
          if (response.data == null) {
            return;
          }
          this.contacts = response.data;
          this.contacts.forEach(e => {
            e.fullname = this.getName(e.firstname, e.surname);
            e.phone = e.phone != null ? e.phone.replaceAll(" ", "") : null;
            e.notificationMediaJira = e.notificationMedia;
            e.notificationMedia = this.getNotificationMedia(e.notificationMedia);
          });
        })
        .finally(() => {
          this.loading = false;
        });
    },
    removeContactFromInstallation () {
      let url = `/Contact/ContactInstallation?contactId=${this.selectedContact.id}&installationId=${this.installationId}`
      if (this.selectedContact.roleId) {
        url += `&roleId=${this.selectedContact.roleId}`
      }
      this.axios.delete(url, )
        .then(() => {
          this.$snotify.success(this.$t('contacts.contactDeletedSuccessfully'));
          this.getContacts();
        })
    },
  }
};
</script>
<style>
.installationContactList .k-grid table tr:hover td {
  background: rgb(219, 219, 219) !important;
  cursor: pointer !important;
}
</style>