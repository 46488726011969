<template>
  <!--
    Used to add a contact to Installation
  -->
  <div class="contactsAddToInstallation">
    <form>
      <div class="form-group">
        <h4 class="m-0 mt-3">
          {{ $t('contact') }}
        </h4>
        <hr class="m-0 mb-3">
        <LoadingPlaceholder v-if="loading" />
        <template v-else>
          <label>{{ $t('contact') }}</label>
          <Multiselect
            id="contact"
            v-model="selectedContact"
            tag-placeholder="Not found"
            placeholder="Select a Contact"
            :options="searchedContacts"
            select-label=""
            :multiple="false"
            :taggable="true"
            track-by="id"
            :custom-label="contactLabel"
            :hide-selected="false"
            :searchable="true"
            :close-on-select="true"
            :internal-search="false"
            class="mb-4"
            @search-change="searchContact"
          />
        </template>
        <LoadingPlaceholder v-if="loadingRoles" />
        <template v-else>
          <hr class="m-0 mb-3">
          <label>Role</label>
          <Multiselect
            id="role"
            v-model="roleId"
            tag-placeholder="Not found"
            placeholder="Select a Role"
            :options="contactRoles"
            select-label=""
            :multiple="false"
            :taggable="true"
            track-by="id"
            label="name"
            :hide-selected="false"
            :searchable="true"
            :close-on-select="true"
            class="mb-4"
          />
        </template>
      </div>
      <button 
        class="btn btn-primary float-right"
        :disabled="!selectedContact"
        @click.prevent="addContact()"
      >
        <font-awesome-icon
          class="mr-2"
          icon="check"
        />{{ $t('add') }}
      </button>
      <div class="clearfix" />
    </form>
  </div>
</template>

<script>
export default {
  name: "ContactAdd",
  components: {
    Multiselect: () => import('vue-multiselect'),
  },
  props: {
    installationId: {
      type: String,
      required: false,
      default: null
    }
  },
  data () {
    return {
      selectedContact: null,
      roleId: null,
      contactRoles: null,
      searchedContacts: null,
      loadingRoles: false,
      loading: false
    }
  },
  created () {
    this.getContacts();
    this.getContactRoles();
  },
  methods: {
    contactLabel ({ firstname, surname }) {
      return `${firstname} ${surname}`
    },
    searchContact (query) {
      this.searchedContacts = this.contacts.filter(x => (x.email && x.email.toLowerCase().includes(query.toLowerCase()) ||
      (x.firstname && x.firstname.toLowerCase().includes(query.toLowerCase())) ||
      (x.surname && x.surname.toLowerCase().includes(query.toLowerCase())) ||
      (x.firstname && x.surname && `${x.firstname} ${x.surname}`.toLowerCase().includes(query.toLowerCase()))))
    },
    getContacts () {
      this.loading = true;
      this.axios.get(`/Contact/ContactsToAdd?installationId=${this.installationId}`)
        .then((response) => {
          if (response == null) {
            return;
          }
          if (response.data == null) {
            return;
          }
          this.contacts = response.data
          this.searchedContacts = this.contacts
        })
        .finally(() => {
          this.loading = false;
        });
    },
     getContactRoles () {
      this.loadingRoles = true;
      this.axios.get('/Contact/ContactRoles')
        .then((response) => {
          if (response == null) {
            return;
          }
          if (response.data == null) {
            return;
          }
          this.contactRoles = response.data.sort((a, b) => {
            return a.name.localeCompare(b.name);
          });
        })
        .finally(() => {
          this.loadingRoles = false;
        });
    },
    addContact () {
      let url = `/Contact/ContactInstallation?installationId=${this.installationId}&contactId=${this.selectedContact.id}`
      if (this.roleId) {
        url += `&roleId=${this.roleId.id}`
      }
      this.axios.post(url)
        .then(() => { 
          this.$snotify.success(this.$t('contacts.contactAddedSuccessfully'));       
          this.$emit("reload");
        })
    },
  }
}
</script>
<style scoped>
.contactsAddToInstallation .progress {
  display: flex;
  flex: 1 1 auto;
  width: 1%;
  height: 20px;
  font-size: 1rem;
}
</style>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style>